.section-header {
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
}
.top-filter {
  top: calc(3rem + 9px);
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: $theme-background-white-2;
}

.btn {
  border: 0px;
}

.sorting {
  .dropdown-menu {
    top: unset;
  }
}

.table-responsive {
  &.maintain-height {
    min-height: calc(100vh - 282px);
  }
  &.maintain-max-height {
    max-height: calc(100vh - 282px);
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
  .table-custom {
    border-collapse: collapse;
    width: 100%;
    .profileImage {
      display: flex;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: #512da8;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #fff;
      text-align: center;
      margin: 5px 0;
    }
    th,
    td {
      height: 42px;
      padding: 8px 16px !important;
      vertical-align: middle;
      font-family: 'PlusJakartaSans Regular';
      font-size: 1rem;
      letter-spacing: 1px;
      color: $theme-grey-9 !important;
      &.min-width-160 {
        min-width: 10rem;
      }
    }
    th {
      background-color: $theme-white-2 !important;
      color: $theme-grey-7 !important;
      height: 3rem;
      text-wrap: nowrap;
      font-weight: 400;
      &.filter-field {
        background-color: $theme-white-1 !important;
      }
    }
    .dropdownItem {
      padding: 0;
      width: 100%;
      text-align: left;
      height: 42px;
      vertical-align: middle;
      font-family: 'PlusJakartaSans Regular';
      font-size: 1rem;
      letter-spacing: 1px;
      color: $theme-grey-9;
      display: flex;
      justify-content: space-between;
    }
    .table-checkbox {
      input[type='checkbox'] {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }
  &:has(.inverse) {
    margin: 1rem auto;
    width: 95%;
    border: 0.5px solid $theme-grey-10;
    border-radius: 6px;
    tr {
      &:not(:last-child) {
        border-bottom: 0.5px solid $theme-grey-10;
      }
      td {
        padding: 0 !important;
        > div {
          background-color: $theme-white-2 !important;
          vertical-align: top;
          padding: 16px !important;
          > span {
            background-color: $theme-green-4;
            color: $theme-green-1;
          }
          p {
            color: $theme-green-1;
          }
        }
        &:last-child {
          img {
            // width: 2rem;
            height: 1.5rem;
            filter: brightness(0);
          }
        }
      }

      &:hover {
        cursor: pointer;
        td {
          > div {
            background-color: $theme-green-1 !important;
            p,
            span {
              color: $theme-white-1;
            }
            > span {
              background-color: $theme-white-4;
            }
            img {
              filter: brightness(5);
            }
          }
        }
      }
    }
    th {
      background-color: $theme-white-1 !important;
      font-family: 'Inter Medium';

      border-bottom: 0.5px solid $theme-grey-10;
    }
  }

  &:has(.bordered) {
    border-bottom: 1px solid $theme-grey-10;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    max-height: unset;
    min-height: unset;
    tbody {
      tr {
        border: 1px solid $theme-grey-10;
        &:last-child {
          border-bottom: 0;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
  &:has(.version) {
    min-height: unset;
    tbody,
    thead {
      tr {
        border-bottom: 1px solid $theme-grey-10;
      }
    }
    th {
      background-color: transparent !important;
    }
  }
  &:has(.nowrap) {
    .table-custom {
      th.nowrap,
      td.nowrap {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 30rem;
        // .submission-column {
        //   width: 100%;
        //   display: flex;
        //   justify-content: space-between;
        //   gap: 1rem;
        //   .border {
        //     border-right: 1px solid $theme-grey-8 !important;
        //     height: 1.6rem;
        //   }
        .show-edit:empty {
          &::after {
            content: 'Edit here';
            color: $theme-grey-10;
            font-style: italic;
          }
        }
        // }
      }
    }
  }
  &:has(.entries) {
    .table-custom {
      .dropdownItem {
        height: 2.5rem !important;
      }
    }
  }
}

.table-folder-filter-list {
  min-width: 22rem;
  box-shadow: -2px 2px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  .folder-filter-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    background-color: $theme-white-2;
    padding: 0 1rem;
  }
  .filters {
    display: flex;
    gap: 1rem;
    padding: 0.5rem 1.5rem;
    position: relative;
    > label {
      flex-grow: 1;
      border: 2px solid $theme-grey-1;
      padding: 0.5rem;
      text-align: center;
      cursor: pointer;
      user-select: none;
      &.active {
        background-color: $theme-green-1;
        border-color: $theme-green-1;
        color: $theme-white-1;
      }
    }

    .filter-modal {
      position: absolute;
      top: 3rem;
      right: 1.5rem;
      min-width: 12rem;
      z-index: 99;
      overflow: hidden;
      background-color: $theme-white-1;
      border-radius: 5px;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    }
  }
  .folder-list {
    margin-top: 0.25rem;
    height: auto;
    max-height: 33rem;
    overflow-y: auto;

    > div {
      padding: 0.75rem 1.5rem;
      &.selected,
      &:hover {
        background-color: $theme-green-1;
        label {
          color: $theme-white-1;
        }
        > img {
          filter: brightness(5);
        }
      }
    }
  }
}
