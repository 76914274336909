.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  height: 70px;
  z-index: 10;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
  padding: 0 30px;
  .header-left {
    width: 220px;
    display: flex;
    gap: 1rem;
    align-items: center;
    img {
      width: auto;
      height: 35px;
    }
    .title {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
  .header-center {
    // width: calc(100% - 420px);
    width: auto;
    flex-grow: 1;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    padding: 0 1.5rem;
    > img {
      width: auto;
      height: 30px;
    }
    .custom-breadcrumb {
      display: flex;
      gap: 1rem;
      align-items: baseline;

      > div {
        img {
          height: 0.8rem;
        }
        &:first-child {
          img {
            display: none;
          }
          label {
            font-size: 1.5rem;
          }
        }

        &:last-child {
          label {
            color: $theme-green-1;
          }
        }
        &:not(:last-child) {
          img {
            filter: grayscale(1);
          }
        }
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    .user-icon {
      width: 50px;
      height: 50px;
      background-color: $theme-green-1;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-family: 'PlusJakartaSans Medium';
        font-size: 1rem;
        color: #ffffff;
      }
    }
    .user-info {
      display: flex;
      flex-direction: column;
      text-align: center;
      .user-name {
        color: $theme-green-1;
        font-size: 1rem;
        font-family: 'PlusJakartaSans Medium';
      }
      .user-role {
        font-size: 0.9rem;
        font-family: 'PlusJakartaSans Regular';
        width: max-content;
      }
    }
  }
}
.switch_company {
  display: block;
  z-index: 15;
  position: fixed;
  width: 100%;
}

@media screen and (max-width: #{$tablet-max-width}px) {
  .header {
    padding: 0 15px;
    .header-left {
      width: 130px !important;
    }
    .header-center {
      gap: 1rem;
      > img {
        height: 25px;
      }
      .custom-breadcrumb {
        > div {
          &:first-child {
            label {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
}
