.report-template {
  .section {
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../images/icons/arrow-down.svg');
      position: absolute;
      right: 10px;
      top: 10px;
      zoom: 1.3;
    }
    &.closed {
      &::after {
        transform: rotateX(180deg);
        top: 2px;
      }
    }
  }
}
