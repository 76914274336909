.home-main {
  .today-statistics {
    background-color: $theme-white-1;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);

    .stats-bar {
      width: 100%;
      height: 80px;
      display: flex;
      gap: 1.5rem;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      border: 1px solid $theme-grey-8;
      border-radius: 5px;

      .vertical-line {
        width: 2px;
        height: 1.5rem;
        background-color: $theme-green-1;
      }
    }
  }

  .large-button {
    padding: 1.75rem 3.5rem;
  }
}
