@for $i from 1 through 9 {
  .space-#{$i} {
    width: 100%;
    height: 0;
    margin-top: #{$i * 0.25}rem;
  }
}

$sizes: 10, 20, 30, 40, 50;
@each $size in $sizes {
  .space-#{$size} {
    width: 100%;
    height: 0;
    margin-top: #{$size}px;
  }
}

// SET COLORS FOR TEXT, INPUT FIELDS
$colorsArray: (
  'black-1': $theme-black-1,
  'green-1': $theme-green-1,
  'green-2': $theme-green-2,
  'green-3': $theme-green-3,
  'grey-1': $theme-grey-1,
  'grey-2': $theme-grey-2,
  'grey-7': $theme-grey-7,
  'red-1': $theme-red-1,
  'white-1': $theme-white-1,
  'white-2': $theme-white-2,
  'blue-1': $theme-blue-1,
);

@each $name, $color in $colorsArray {
  .theme-text-#{$name} {
    color: $color;
  }
}

// CREATE BACKGROUND COLORS
$backgroundColorsArray: (
  'green-1': $theme-green-1,
  'green-3': $theme-green-3,
  'green-4': $theme-green-4,
  'white-1': $theme-white-1,
  'white-2': $theme-white-2,
  'white-3': $theme-white-3,
  'grey-1': $theme-grey-1,
  'grey-5': $theme-grey-5,
  'grey-13': $theme-grey-13,
  'black-1': $theme-black-1,
);

@each $name, $color in $backgroundColorsArray {
  .theme-background-#{$name} {
    background-color: $color;
  }
}

// CREATE LOADER COLORS
$loaderColorsArray: (
  'green-1': $theme-green-1,
  'white-1': $theme-white-1,
  'red-1': $theme-red-1,
  'black-1': $theme-black-1,
);

@each $name, $color in $loaderColorsArray {
  .loader-#{$name} {
    color: $color !important;
  }
}

// CREATE BORDERS COLORS
$borderColorsArray: (
  'grey-1': $theme-grey-1,
  'grey-10': $theme-grey-10,
);
@each $name, $color in $borderColorsArray {
  $sides: top, bottom, left, right;
  @each $side in $sides {
    @for $i from 1 through 2 {
      .border-#{$side}-#{$name}-h-#{$i} {
        border-#{$side}: #{$i}px solid $color;
      }
    }
  }
}

@for $i from 1 through 2 {
  .border-grey-1-h-#{$i} {
    border: #{$i}px solid $theme-grey-1;
  }
}

.rounded-sm {
  border-radius: 5px;
}
.rounded-md {
  border-radius: 8px;
}

$positions: 90, 180, 270;
@each $position in $positions {
  .rotate-#{$position} {
    transform: rotate(#{$position}deg);
  }
}

// CREATE WIDTHS IN REM, ADD REQUIRED WIDTH IN PIXEL FROM FIGMA DESIGN
$widthArray: 633, 453, 40, 20, 120, 140, 150, 175, 200, 250, 300, 15, 230, 290, 234, 112, 25, 147,
  35, 48, 70;
@each $width in $widthArray {
  .theme-width-#{$width} {
    width: #{calc($width / 16)}#{'rem'} !important;
  }
}
.min-width-176 {
  min-width: 12.625rem !important;
}
// CREATE Height IN REM, ADD REQUIRED WIDTH IN PIXEL FROM FIGMA DESIGN
$heightArray: 10, 20, 120, 40, 48, 89, 15, 650, 504, 114, 25, 666, 307, 224, 44, 112, 33, 70, 60,
  300, 530;
@each $height in $heightArray {
  .theme-height-#{$height} {
    height: #{calc($height / 16)}#{'rem'};
  }
}

// CREATE FONT SIZES FROM .7REM TO 3.5REM
@function str-replace($string, $search, $replace) {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
@for $i from 07 through 35 {
  $name: $i * 0.1;
  $name: str-replace('' + $name, '.', '_');
  $index: str-index('' + $name, '_');

  @if ($i < 10 and $index == 1) {
    $name: '0' + $name;
  }
  .theme-size-#{$name} {
    font-size: #{$i * 0.1}#{'rem'};
  }
}

@for $i from 1 through 35 {
  $name: $i;
  .ms-#{$name} {
    margin-left: #{$spacer * $i * 0.25} !important;
  }
}
