.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $theme-black-2;

  .back {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  & > div {
    background-color: white;
    padding: 1rem 0;
    border-radius: 0.5rem;
    text-align: center;

    & .head,
    & .body {
      padding: 0 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .progress {
      border-radius: 0;
      background: $theme-green-1;
      height: 0.5rem;
      margin: 2rem 0 2.5rem;

      & .remaining {
        background: $theme-grey-4;
        border: 1px solid white;
        border-left-width: 0;
        border-right-width: 0;
        width: var(--percent);
        position: relative;
        margin-left: auto;
      }
    }
  }
}
.custom-company-leave-process {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $theme-black-2 !important;
  > div {
    background-color: $theme-white-1;
    border-radius: 10px;
    padding: 30px;
  }
}
.alert-modal {
  .alert-content {
    width: 100%;
    height: 100%;
    position: relative;
    label.alert-title {
      margin-top: 1rem;
      margin-bottom: 0rem;
      font-size: 1.6rem;
      font-family: 'Inter Medium';
      color: $theme-black-1;
    }
    p.alert-message {
      font-size: 1.1rem;
      font-family: 'Inter Regular';
      color: $theme-black-1;
      text-align: center;
    }
    .btn-close {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    img.header-icon {
      width: auto;
      height: 4rem;
    }
    .form-error-messages {
      height: auto;
      max-height: 40vh;
      overflow: auto;
      width: 100%;
      padding: 0 10%;
    }
  }
}

.preview-modal {
  width: 100%;
  padding: 0.75rem 2rem;
  height: auto;
  max-height: 60vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .back-arrow {
    position: absolute;
    top: 2rem;
    left: 2rem;
    cursor: pointer;
  }

  .page-listing {
    border: 1px solid $theme-grey-1;
    border-radius: 5px;
    overflow: hidden;
    > div {
      width: 100%;
      height: 3rem;
      padding: 0 1rem;
      &:not(:last-child) {
        border-bottom: 1px solid $theme-grey-1;
      }
    }

    div:nth-child(even) {
      background-color: $theme-white-2;
    }
  }

  .field-component-box {
    width: 100%;

    &.with-frame {
      padding: 1rem;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
      border: 1px solid $theme-grey-1;
      border-radius: 5px;
    }

    .vertical-line {
      width: 2px;
      height: 1rem;
      background-color: $theme-green-1;
    }

    .choice-list-option-box {
      width: 100%;
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      > label {
        color: $theme-white-1;
      }
    }
  }
}

.font-style-modal {
  background-color: #fff;
  border: 2px solid $theme-green-1;
  border-radius: 5px;
  width: 20rem;
  padding: 1rem;
  position: absolute;
  top: 3rem;
  right: 0;
  z-index: 99;

  .close-btn {
    position: absolute;
    right: 0.15rem;
    top: 0.15rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }

  .color-display-box {
    width: 3rem;
    height: 2rem;
    border-radius: 5px;
  }

  .dropdown-toggle {
    height: 2.5rem;

    > label {
      font-size: 0.9rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .font-type-field {
    width: 100%;
    border: 1px solid $theme-grey-1;
    border-radius: 4px;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    > div {
      flex-grow: 1;
      img {
        width: 100%;
      }
      &:not(.active):hover {
        img {
          filter: unset;
        }
      }
    }
  }
  .sketch-picker {
    box-shadow: none !important;
    padding: 0 !important;
    > div:nth-child(2) {
      padding-top: 2rem;
      position: relative;
      &::before {
        content: 'Font Color & Opacity';
        position: absolute;
        top: 0.4rem;
        font-family: 'Inter Medium';
        color: $theme-grey-1;
      }
    }
    > div:nth-child(3) {
      div {
        padding-left: 0 !important;
        display: flex;
        flex-direction: column-reverse;
        align-items: baseline;
        input {
          border: 1px solid grey !important;
          box-shadow: none !important;
          width: 100% !important;
          padding: 7px 5px !important;
        }
        label {
          font-family: 'Inter Medium';
          color: $theme-black-6 !important;
        }
      }
      > :first-child {
        margin-right: 0.5rem;
        input {
          border-radius: 8px;
        }
        label {
          text-transform: uppercase !important;
        }
      }
      > :nth-child(2) {
        input {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
      }
      > :last-child {
        input {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
      > :not(:first-child, :last-child) {
        input {
          border-right: 0 !important;
        }
      }
    }
    > div:nth-child(4) {
      display: none !important;
    }
  }
  .colors-list {
    display: flex;
    gap: 0.4rem;
    .color-for-selection {
      display: block;
      border: 1px solid $theme-grey-4;
      width: 23px;
      height: 23px;
      background-color: var(--color);
    }
  }
}

.response-set-list-modal {
  position: absolute;
  bottom: 1.5rem;
  right: 0;
  width: max-content;
  z-index: 99;
  background-color: $theme-white-1;
  border-radius: 5px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid $theme-green-1;
  padding: 0.75rem;

  .response-set-list-modal-header {
    border-bottom: 1px solid $theme-grey-1;
    margin-bottom: 0.75rem;
  }

  .close-btn {
    position: absolute;
    right: 0.15rem;
    top: 0.15rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }

  .response-set-list {
    .response-set-item {
      padding: 0.5rem 0.75rem;
      height: 'fit-content';
      border-radius: 4px;
    }
  }

  .response-set-list-modal-footer {
    border-top: 1px solid $theme-grey-1;
    margin-top: 0.75rem;
  }
}

.add-submission-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  padding: 0 1.5rem;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
}
