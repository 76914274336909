.parent-container {
  position: relative;
}

.add-segment {
  position: absolute;
  right: 0;
  top: 50px;
  min-width: 350px;
  border-radius: 10px;
  background-color: #fff;
  z-index: 9;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);

  .title {
    font-family: 'PlusJakartaSans Medium';
    letter-spacing: 0.23618090152740479px;
    background-color: #f8f8f8;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #2a2b28;
  }
}

.add-group {
  border-left: 0.5px solid #63687480;
  border-right: 0.5px solid #63687480;
  border-radius: 4px !important;
  box-shadow: 0px 2px 3px 0px #00000033;
  font-family: 'Inter Medium';
}

.user-list {
  display: flex;
  position: relative;

  :not(:first-child) {
    margin-left: -15px;
  }
  .avatar-circle {
    width: 35px;
    height: 33px;
    border-radius: 50%;
    background: #ffa600;

    font-family: 'PlusJakartaSans Medium';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.25px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }

  :last-child {
    position: relative;
    img {
      border: 1px solid transparent;
      position: absolute;
      top: 0;
      right: -3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #38cb89;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
