.custom-input-box {
  width: min(100%, 375px);
  text-align: initial;

  label {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  .label-error {
    color: $theme-red-2;
  }
  &:not(.read-only) {
    &:focus-within {
      label {
        color: $theme-green-1;
      }
      input,
      textarea {
        outline-color: $theme-green-1;
      }
      .country-code {
        outline: none;
      }

      @-moz-document url-prefix() {
        input,
        textarea {
          outline-style: solid;
          outline-width: 2px;
          border: none;
        }
      }

      @media not all and (min-resolution: 0.001dpcm) {
        input,
        textarea {
          outline: none;
          border: 2px solid $theme-green-1;
        }
        input::-webkit-contacts-auto-fill-button,
        input::-webkit-credentials-auto-fill-button {
          visibility: hidden;
          display: none !important;
        }
      }
    }
  }

  .shrink-label {
    font-size: 0.8rem;
  }

  .shrink-check-option {
    gap: 0.5rem;
  }

  .custom-input-field {
    position: relative;

    input,
    textarea {
      width: 100%;
      padding: 12px 10px;
      border-radius: 8px;
      border: 1px solid $theme-grey-1;
      display: block;
      font-size: 1rem;
    }
    input::placeholder,
    textarea::placeholder {
      color: $theme-grey-8;
    }

    .input-error {
      border: 1px solid $theme-red-2;
    }

    img.icon {
      width: auto;
      height: 40%;
      position: absolute;
      right: 10px;
      bottom: 30%;
    }

    .shrink-field:not(textarea) {
      height: $field-height-shrink;
    }

    textarea.shrink-field {
      padding: 7px 10px;
    }
  }
  &.read-only {
    input,
    textarea {
      border: 0;
      outline: none;
      cursor: default;
      background: transparent;
    }
  }
  .mobile-number-field {
    display: flex;

    .mobile-number {
      width: calc(100% - 6rem);
      input {
        border-radius: 0 8px 8px 0;
        border-left: 0;
      }
    }
    &:focus-within {
      .country-code {
        outline: 1px auto $theme-green-1;
      }

      @-moz-document url-prefix() {
        .country-code {
          outline-style: solid;
          outline-width: 2px;
          border: none;
        }
      }

      @media not all and (min-resolution: 0.001dpcm) {
        .country-code {
          outline: none;
          border: 2px solid $theme-green-1;
        }
      }
    }

    .country-code {
      width: 6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;
      border-radius: 8px 0 0 8px;
      border: 1px solid $theme-grey-1;
      img {
        width: auto;
        height: 1.5rem;
        border-radius: 4px;
      }
      select {
        outline: none;
        border: 0;
        appearance: none;
        -webkit-appearance: none;
        cursor: pointer;
      }
    }
  }

  .custom-otp-field {
    display: flex;
    justify-content: space-between;
    input {
      width: 13%;
      font-size: 2rem;
      border: 0;
      border-bottom: 2px solid $theme-black-1;
      text-align: center;
      &:focus {
        border-color: $theme-green-1;
        outline: none;
      }
    }
    input + input {
      margin-left: 5px;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }

  .custom-number-range-field {
    position: relative;
    input[type='range'] {
      font-size: 1.5rem;
      width: 100%;
      color: $theme-green-1;
      position: relative;
      overflow: hidden;
    }

    .slider-value {
      position: absolute;
      top: -20px;
      z-index: 999;
    }

    input[type='range']:disabled {
      // filter: grayscale(1);
      // opacity: 0.3;
      cursor: not-allowed;
    }

    /* === WebKit specific styles === */
    input[type='range'],
    input[type='range']::-webkit-slider-runnable-track,
    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      transition: all ease 100ms;
      height: 1.5rem;
      cursor: pointer;
    }

    input[type='range']::-webkit-slider-thumb {
      --thumb-radius: calc((1.5rem * 0.5) - 1px);
      --clip-top: calc((1.5rem - 0.25rem) * 0.5 - 0.5px);
      --clip-bottom: calc(1.5rem - var(--clip-top));
      --clip-further: calc(100% + 1px);
      --box-fill: calc(-100vmax - 1.5rem) 0 0 100vmax currentColor;

      width: 1.5rem;
      background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50%
        calc(0.25rem + 1px);
      background-color: currentColor;
      box-shadow: var(--box-fill);
      border-radius: 1.5rem;

      clip-path: polygon(
        100% -1px,
        0.25rem -1px,
        0 var(--clip-top),
        -100vmax var(--clip-top),
        -100vmax var(--clip-bottom),
        0 var(--clip-bottom),
        0.25rem 100%,
        var(--clip-further) var(--clip-further)
      );
    }

    input[type='range']::-webkit-slider-runnable-track {
      background: linear-gradient($theme-grey-12 0 0) scroll no-repeat center / 100%
        calc(0.25rem + 1px);
    }

    /* === Firefox specific styles === */
    input[type='range'],
    input[type='range']::-moz-range-track,
    input[type='range']::-moz-range-thumb {
      appearance: none;
      transition: all ease 100ms;
      height: 1.5rem;
    }

    input[type='range']::-moz-range-thumb {
      background: currentColor;
      border: 0;
      width: 1.5rem;
      border-radius: 1.5rem;
    }

    input[type='range']::-moz-range-track {
      width: 100%;
      background: $theme-grey-12;
    }

    input[type='range']::-moz-range-progress {
      appearance: none;
      background: currentColor;
      transition-delay: 30ms;
    }

    input[type='range']::-moz-range-track,
    input[type='range']::-moz-range-progress {
      height: calc(0.25rem + 1px);
      border-radius: 0.25rem;
    }

    input[type='range']::-moz-range-thumb,
    input[type='range']::-moz-range-progress {
      filter: brightness(100%);
    }
  }

  &.filter-field {
    width: 10rem;
    input {
      border-radius: 4px;
      background-color: $theme-green-4;
      border-color: $theme-grey-8;
    }
  }
}

.custom-tag-input-box {
  width: min(100%, 375px);

  .custom-tag-input-field {
    display: flex;
    gap: 0.5rem;
    border: 1px solid $theme-grey-1;
    background-color: $theme-green-2;
    border-radius: 8px;
    overflow: hidden;
    .input-tag-box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.5rem;
      background-color: $theme-green-1;
      border-radius: 4px;
      // box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.25);
      > label {
        color: $theme-white-1;
      }
    }
    input {
      background-color: transparent;
      border: none;
      width: 100%;
      border-radius: 0 8px 8px 0;
    }

    &:focus-within {
      border: none;
      outline: 2px solid $theme-green-1;
      border-radius: 10px;
      input {
        outline: none;
      }

      @media not all and (min-resolution: 0.001dpcm) {
        border: 2px solid $theme-green-1;
        outline: none;
      }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      appearance: textfield;
      -moz-appearance: textfield;
    }

    .shrink-tag {
      font-size: 0.8rem;
    }
    .shrink-field {
      height: $field-height-shrink;
    }
  }
}

.tab-selection-field {
  border: 1px solid $theme-grey-1;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  width: min(100%, 375px);

  > div {
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0;
    background-color: $theme-white-2;
    cursor: pointer;
    user-select: none;

    &.active {
      background-color: $theme-green-1;
      color: $theme-white-1;
    }

    &:not(:last-child) {
      border-right: 1px solid $theme-grey-1;
    }
  }
}

.color-field-box {
  width: min(100%, 375px);
  text-align: initial;

  > label {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  &.focused {
    label {
      color: $theme-green-1;
    }
    .color-field {
      border: 2px solid $theme-green-1;
    }
  }

  .color-field {
    width: 100%;
    height: 3rem;
    border-radius: 8px;
    border: 1px solid $theme-grey-1;
    display: flex;
    align-items: center;
    overflow: hidden;

    .color-value {
      font-size: 1rem;
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding: 0 0.75rem;
    }
    .color-box {
      border-left: 1px solid grey;
      border-radius: 0 !important;
      width: 3rem;
      height: 3rem;
      cursor: pointer;
    }
  }

  .shrink-label {
    font-size: 0.8rem;
  }

  .shrink-field {
    height: $field-height-shrink;
    .color-value {
      font-size: 0.8rem;
      padding: 0 0.5rem;
    }
    .color-box {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

.toggle-switch {
  width: 2.8rem;
  height: 1.7rem;
  border: 2px solid $theme-grey-1;
  border-radius: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.1rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;

  .toggler {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: $theme-white-1;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 0.6rem;
      height: 0.6rem;
    }
  }
}
.toggle-switch-disabled {
  background-color: $theme-grey-2;
  border-color: $theme-grey-2;
  justify-content: end;
}
.toggle-switch-on {
  background-color: $theme-green-1;
  border-color: $theme-green-1;
  justify-content: end;
}
.toggle-switch-off {
  .toggler {
    background-color: $theme-grey-1;
  }
}

.custom-form-button {
  width: fit-content;
  min-width: 8.25rem;
  padding: 0 1rem;
  border-radius: 8px;
  height: $button-height;
  // box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  user-select: none;
  text-wrap: nowrap;

  &.shrink-button {
    height: 2.1rem;
    padding: 0 0.75rem;
    border-radius: 6px;

    .spinner-border {
      --bs-spinner-width: 1.5rem;
      --bs-spinner-height: 1.5rem;
      --bs-spinner-border-width: 0.2em;
    }
  }

  &.less-rounded {
    border-radius: 5px;
  }

  &.lg-size {
    height: 3rem;
    width: 12rem;
    padding: 0;
    border-radius: 5px;
  }

  &.min-width {
    min-width: auto;
  }

  > img {
    max-height: 1.5rem;
  }
}

// CREATE BUTTON BACKGROUND AND COLORS
$buttonColorsArray: (
  green-1: (
    //background-color
    $theme-green-1,
    //color
    #ffffff,
    //border-color
    $theme-green-1,
  ),
  green-2: (
    transparent,
    $theme-green-1,
    $theme-green-1,
  ),
  green-3: (
    $theme-green-2,
    $theme-green-1,
    $theme-green-2,
  ),
  white-1: (
    #ffffff,
    $theme-black-1,
    $theme-grey-1,
  ),
  white-2: (
    $theme-white-3,
    $theme-grey-2,
    $theme-white-3,
  ),
  grey-1: (
    $theme-grey-2,
    $theme-white-1,
    $theme-grey-2,
  ),
  grey-2: (
    $theme-grey-5,
    $theme-grey-1,
    $theme-grey-5,
  ),
  red-1: (
    $theme-red-1,
    #ffffff,
    $theme-red-1,
  ),
  red-2: (
    $theme-red-3,
    #ffffff,
    $theme-red-3,
  ),
  blue-1: (
    $theme-blue-1,
    #ffffff,
    $theme-blue-1,
  ),
  blue-2: (
    $theme-blue-2,
    $theme-blue-1,
    $theme-blue-2,
  ),
  blue-3: (
    $theme-blue-3,
    #ffffff,
    $theme-blue-3,
  ),
  orange-1: (
    $theme-orange-2,
    $theme-orange-1,
    $theme-orange-2,
  ),
  orange-2: (
    $theme-orange-1,
    #ffffff,
    $theme-orange-1,
  ),
);

@each $name, $color in $buttonColorsArray {
  .theme-button-#{$name} {
    background-color: nth($color, 1) !important;
    color: nth($color, 2) !important;
    border: 1px solid nth($color, 3) !important;
  }

  .theme-button-#{$name}-loading {
    background-color: lighten($color: nth($color, 1), $amount: 10%);
    border-color: lighten($color: nth($color, 1), $amount: 10%);
  }

  .theme-button-#{$name}-disabled {
    background-color: lighten($color: nth($color, 1), $amount: 20%) !important;
    border-color: lighten($color: nth($color, 1), $amount: 20%) !important;
    cursor: not-allowed;
  }

  .theme-button-#{$name}-spinner {
    color: nth($color, 2) !important;
  }
}

.form-field-error {
  font-size: 0.8rem;
  color: $theme-red-2;
  font-family: 'Inter Regular';
  margin: 5px 0 0 0;
  text-align: left;
  &::first-letter {
    text-transform: uppercase;
  }
}

.custom-check-option {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  user-select: none;

  input[type='checkbox'].shrink-checkbox,
  input[type='radio'].shrink-checkbox {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.custom-range-field {
  padding: 1rem 0;
  border: 1px solid $theme-grey-1;
  border-radius: 1rem;
  min-width: 8.3rem;
}

.custom-industry-field {
  padding: 1rem 0;
  border: 1px solid $theme-grey-1;
  background: linear-gradient($theme-grey-5 42%, #ffffff 30%);
  border-radius: 1rem;
  min-width: 8.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  position: relative;
  img {
    width: 2rem;
    height: 2rem;
    &.selected {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      width: 1.5rem;
    }
  }
  &.disabled {
    background-color: $theme-grey-6;
    cursor: not-allowed;
  }
  &.category {
    background-color: $theme-grey-5;
    padding: 0.3rem;
  }
}

.custom-feature-field {
  padding: 1rem 0;
  border: 1px solid $theme-grey-1;
  border-radius: 1rem;
  min-width: 8.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  position: relative;
  img {
    width: 2rem;
    height: 2rem;
    &.selected {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      width: 1.5rem;
    }
  }
  &.disabled {
    background-color: $theme-white-1;
    cursor: not-allowed;
  }
  &.category {
    background-color: $theme-grey-5;
    padding: 0.3rem;
  }
}

.logo-container {
  border: 1px solid $theme-grey-3;
  cursor: pointer;
  border-radius: 10px;
  padding: 1rem 0;
  box-shadow: 0px 2.2192983627319336px 3.3289475440979004px 0px #00000040;
  img {
    width: 140px;
  }
}

input[type='checkbox'],
input[type='radio'] {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid $theme-grey-1;
  margin-top: 0;
  cursor: pointer;
}

input[type='checkbox']:checked,
input[type='radio']:checked {
  background-color: $theme-green-1;
  border-color: $theme-green-1;
}

input[type='checkbox']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus-within,
input[type='radio']:focus-within {
  border: 2px solid $theme-grey-1;
  box-shadow: none;
  outline: none;
}

input[type='checkbox']:checked:focus,
input[type='radio']:checked:focus {
  border: 2px solid $theme-green-1;
  box-shadow: none;
  outline: none;
}

input[type='checkbox']:disabled,
input[type='radio']:disabled {
  background-color: $theme-grey-1;
  border: none;
}

html[dir='rtl'] {
  .custom-input-field img.icon {
    right: auto;
    left: 10px;
  }

  .mobile-number-field {
    input {
      border-radius: 8px 0 0 8px;
      border-left: 1px solid $theme-grey-1;
      border-right: unset;
    }

    .country-code {
      border-radius: 0 8px 8px 0;
    }
  }

  .form-field-error {
    text-align: right;
  }

  .color-field {
    .color-box {
      border-left: none;
      border-right: 1px solid grey;
    }
  }
}

.form-hint {
  line-height: 0.5rem !important;
}

.form-input-minus-150 {
  width: calc(100% - 150px) !important;
}

.custom-field {
  width: 100%;
  padding: 12px 10px;
  border-radius: 8px;
  border: 1px solid $theme-green-1;
  display: block;
  font-size: 1rem;
}

.custom-select {
  width: auto;
  border-radius: 8px;
  border: 0 !important;
  font-size: 1rem;
}

.example-box {
  width: 50%;
  border-radius: 0 8px 8px 0;
  border: 1px solid $theme-green-1;
  font-size: 1rem;
  text-align: center;
  color: #ffffff;
  background-color: $theme-green-1;
}
.dropdown-item {
  min-width: 3rem;
  width: 12rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  span {
    margin-top: 0rem !important;
    padding: 0.5rem 0 !important;
    font-family: 'Inter Regular' !important;
    font-size: 1rem !important;
    color: $theme-grey-1;
  }
  &.selected {
    background-color: $theme-green-1;
    span {
      color: $theme-white-1;
    }
  }
  &:hover {
    background-color: $theme-green-1 !important;
    > span,
    .arrow {
      color: $theme-white-1;
      + .submenu {
        display: block;
        .dropdown-menu {
          display: block;
        }
      }
      > img {
        filter: brightness(5) !important;
      }
    }
  }
  .arrow {
    + .submenu {
      display: none;
      button:empty {
        display: none;
      }
      .dropdown-menu {
        margin-left: 1.5rem;
        margin-top: -1.9rem;
      }
    }
  }
}

.signature-container {
  display: block;
  position: absolute;
  border: #00000040;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}

.signature-cross {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 1rem;
}
.bubble {
  background: $theme-green-1;
  color: white;
  padding: 0px 4px;
  position: absolute;
  border-radius: 4px;
  transform: translateX(-50%);
  top: -25px;
  left: var(--styles);
}
.bubble::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 2px;
  background: $theme-green-1;
  bottom: -1px;
  left: 50%;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
